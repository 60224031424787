@import './theme/color.scss';
@import './theme/font.scss';


/************** font weight variables***********/
$font-weight-normal: 300;
$font-weight-medium: 500;
$font-weight-bold: 600;

/*********************************** font family ****************************/
.common-button, .common-button:focus, .common-button:hover{
    font-size: $font14;
    font-weight: 500;
    color: $whiteColor;
    text-transform: uppercase;
    padding: 12px;
    width: 100%;
    background: $primaryColor;
    border-color: $primaryColor;
    border-radius: 30px;
    font-family: "Montserrat-Medium";
    outline: 0px;
    box-shadow: unset;
    border: 0px;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn{
    background-color: $primaryColor;
    border-color: $primaryColor;
    opacity: 0.7;
}
.btn-check:active+.btn-secondary:focus, .btn-check:checked+.btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show>.btn-secondary.dropdown-toggle:focus{
    outline: 0px;
    box-shadow: unset;
    border: 0px;
}

.disable-link{
    pointer-events: none !important;
    opacity: 0.7 !important;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1) !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.4);
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  .layout-main-auth{
    width: 100%;
    height: calc(100vh - 65px);
    margin-top: 65px;
    margin-left: 10px;
    .main-page-wrapper{
      width: 100%;
      height: 100%;
      // padding: 0 15vw;
      & > div:first-child{
        height: 100%;
        width: 100%;
      }
    }
    .main-page-wrapper-absolute{
      width: 100%;
      height: 100%;
      margin-left: 38px;
      & > div:first-child{
        height: 100%;
        width: 100%;
      }
    }
  }

  .layout-siderbar-unstatic{
    margin-left: 85px !important;
  }

  .layout-main-no-auth{
    padding: 0px !important;
    margin: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    .main-page-wrapper{
      padding: 0px !important;
    }
    .main-page-wrapper-absolute{
      padding: 0px !important;
    }
  }

/*------ loading-Spinner css ------------*/
.loading-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1051;
}

.main-wrapper{
  margin: 0 15vw;
}

.mobile-screen-wrapper{
    background: url('/assets/mobile_view_bg.png')no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    margin: 0px;
    @media (max-width: 767px) {
      display: block !important;
      height: 100vh;
    }
    .logo{
        background: url('/assets/Logo.png')no-repeat;
        background-size: contain;
        background-position: 0 100%;
        width: 253px;
        height: 168px;
    }
    .mobile-screen{
       @extend .centered-style;

        p{
          font-size: $font18;
          color: $whiteColor;
          font-family: "Montserrat-Regular";
          font-weight: 400;
          margin: 60px 0 28px;
        }
        .app-links{
          img{
            margin-top: 30px;
          }
        }
    }
}
.centered-style{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.radio-control {
  margin: 5px;
  vertical-align: middle;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-control+span {
  display: inline-block;
  background: url('/assets/radio_off.png') no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  vertical-align: text-top;
  margin: 1px 5px 0 0;
}

// .checkboxControl:checked+span,
// .checkboxControl.checked+span {
//     background-image: url('/assets/images/checkOn.png');
// }

.radio-control:checked+span {
  background-image: url('/assets/radio_on.png');
}

.common-delete-button, .common-delete-button:focus, .common-delete-button:hover{
   border: 1px solid $redBtnColor;
   color: $redBtnColor;
   background-color: $whiteColor;
   padding: 7px 30px;
   width: auto;
   font-size: $font13;
   font-weight: 500;
   text-transform: capitalize;
}

/*-------------- Confirm Popup ---------------*/
.confirm-alert{
  width: 320px !important;
  .modal-content{
    border-radius: 10px !important;
    .modal-body{
      padding: 20px 20px 20px 20px !important;
      .heading-text{
        text-align: center;
        font-family: 'Montserrat-SemiBold';
        font-size: 16px;
        padding-bottom: 10px;
      }
      .confirm-text{
        // color: $textColor;
        font-family: 'Montserrat-Medium';
        font-size: 14px !important;
        // font-size: 8px;
        font-weight: 400 !important;
        line-height: 18px;
        text-align: center;
      }
      .btn-div{
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        .outline-btn{
          width: 86px;
          height: 30px;
          border-radius: 5px;
          border: none;
          // border: 1px solid $primaryColor;
          // color: $primaryColor;
          font-family: 'Montserrat-SemiBold';
          font-size: 16px;
          font-weight: 400;
          background-color: $whiteColor;
          margin-left: 10px;
          &:hover{
            background-color: $borderColor;
          }
        }
        .danger-btn{
            border-color: #ff0000 !important;
            color: #ff0000 !important;
        }
        .success-btn{
            border-color: #17b82c !important;
            color: #17b82c !important;
        }
        .solid-btn{
          width: 86px;
          height: 30px;
          border-radius: 5px;
          background-color: $whiteColor;
          border: none;
          color: $labelColor;
          font-family: 'Montserrat-SemiBold';
          font-size: 16px;
          font-weight: 400;
          &:hover{
            background-color: $borderColor;
          }
        }
      }
    }
  }
}

.negative-btn, .positive-btn{
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0px;
  font-family: "Montserrat-Medium";
}

.negative-btn, .negative-btn:focus, .negative-btn:hover{
  background-color: transparent;
  padding: 0px 23px;
  border: 1px solid transparent;
  color: #a58b89;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
}

.positive-btn, .positive-btn:focus, .positive-btn:hover{
  background-color: #a58b89;
  padding: 0px 23px;
  border: 1px solid #a58b89;
}

.otp-invalid {
  border: 1px solid $dangerColor !important;
}
.otp-error-msg{
  font-size: 12px;
  color: $dangerColor;
  font-family: 'Montserrat-Medium';
  text-align: left;
}
@media (max-width: 480px) {
  .main-wrapper{
    margin: 0;
  }
  .layout-siderbar-unstatic{
    margin-left: 16px !important;
    margin-right: 16px;
  }
}